import React from "react";
import "./Css/Evaluation.css"
import logo from "../assets/images/neologicxlogo1.png";
import Header from "./Header";
import Card from "./Card";
import Whocan from "./Whocan";
import Footer from "./Footer";
import { Link } from "react-router-dom";
const EvaluationForm = () => {
  return (
    <>
      <div className="">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
          <div className="container">
            <Link class="navbar-brand" to="/">
              <img src={logo} className="img-fluid" alt="logo" />
            </Link>
            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="toggler-icon top-bar"></span>
            <span class="toggler-icon middle-bar"></span>
            <span class="toggler-icon bottom-bar"></span>
          </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <Link class="nav-link" to="#">
                    Home <span class="sr-only">(current)</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#about">
                    About Us
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#forms">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Header />
      <Card />
      <Whocan />
      <Footer />
    </>
  );
};

export default EvaluationForm;
